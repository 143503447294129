import { postRequest } from "@/utils/request_utils"

export default {
    // 查询
    queryPage: (data)=> {
        return postRequest(`/api-base/project/queryPage`,{data});
    },
    // 新增
    save: (data)=> {
        return postRequest(`/api-base/project/save`,{data});
    },
    update: (data) =>{
        return postRequest(`/api-base/project/update`,{data});
    },
    delete: (data) =>{
        return postRequest(`/api-base/project/deleteEntity`,{data});
    }
}
